.typeNowContainer {
  margin: 30px 0px 0px 0px;
  position: relative;
}
.typeNowContainer .editable {
  outline: none;
  border-width: 0;
  font-size: 45px;
  width: 100%;
  max-width: 1920px;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 350;
  font-style: normal;
  z-index: 20;
  position: relative;
  -webkit-text-stroke: 1px white;
}

.typeNowContainer [contenteditable="true"]:empty:before {
  content: attr(data-placeholder);
  color: #afafaf;
  padding-left: 19px;
}

.popupContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}

.popupImage {
  width: 100%;
}

.popupButtons {
  display: flex;
  align-items: center;
  gap: 35px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
.addWordModal {
  display: flex;
}

.fullscreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white; /* 배경 색상 */
  display: flex;
  z-index: 500; /* 다른 요소 위에 표시되도록 설정 */
}

.outcome .image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* 이미지 비율 유지하며 크기 조정 */
}

.fullscreenImage {
  width: 100vw;
  height: 100vh;
  object-fit: cover; /* 이미지 비율 유지하며 크기 조정 */
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.subtitle {
  position: absolute;
  bottom: 50px; /* 화면 아래쪽에서 50px 위로 위치 */
  width: 100%;
  text-align: center;
  padding: 0 20px; /* 텍스트가 좌우 끝에 붙지 않도록 패딩 추가 */
  box-sizing: border-box;
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px; /* 153.333% */
  letter-spacing: -0.9px;
}

.musicWrapper {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  z-index: 999;

  border-radius: 300px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;

  width: 268px;
  height: 85px;
  cursor: pointer;
}

.loadMoreButton {
  display: inline-flex;
  align-items: center;
  gap: 16px;

  color: #999;
  font-size: 26px;
  font-weight: 350;
  letter-spacing: -0.78px;

  width: 100%;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 80px;
  cursor: pointer;
}
.floatAddBtn {
  display: flex;
  width: 86px;
  height: 86px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: #fdfdfd;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.floatAddBtn:hover {
  background-color: #007aff;
}

.noContent {
  color: #cbcbcb;
  font-size: 73px;
  font-weight: 300;
  letter-spacing: -2.19px;
  width: 100%;
}

/* Modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.addWordModal {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: #fdfdfd;
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0px 10px;
}
.addWordModalHeader {
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 0.5px solid #999;
  background: #f7f7f7;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
}
.addWordModalHeaderTitle {
  color: #999;
  font-size: 18px;
  font-weight: 350;
  letter-spacing: -0.54px;
}
.addWordModalHeaderSave {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 100px;
  border: 1px solid #999;
  color: #999;
  font-size: 12px;
  font-weight: 350;
  letter-spacing: -0.36px;
  cursor: pointer;
}
.addWordModalBody {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  background: #fff;
  width: 100%;
  color: #999;
  font-size: 30px;
  font-weight: 350;
  letter-spacing: -0.9px;
}

.addWordModalInputWrapper {
  border-bottom: 0.5px dashed #999;
  padding: 10px 0px;
  width: 100%;
}
.addWordModalInput {
  font-size: 30px;
  font-weight: 350;
  letter-spacing: -0.9px;
  outline: none;
  border: none;
  margin-bottom: 10px;
}

.addWordModalTextArea {
  font-size: 30px;
  font-weight: 350;
  letter-spacing: -0.9px;
  outline: none;
  border: none;
  width: 100%;
}

.addWordModalFileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #999;
  text-align: center;
  font-size: 12px;
  font-weight: 350;
  letter-spacing: -0.36px;
  min-height: 222px;
  cursor: pointer;
}

.addWordModalFileUploadHidden {
  display: none;
}

/* Progress bar */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.progressBar {
  width: 80%;
  height: 4px;
  background: #fff;
  animation: progress 2s infinite;
}

@keyframes progress {
  0% {
    width: 0;
  }
  50% {
    width: 80%;
  }
  100% {
    width: 0;
  }
}

.introModal {
  position: relative;
}

.introModalBox {
  display: flex;
  width: 50%;
  height: calc(100% - 280px);
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 117px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  color: #000;
  font-family: "Noto Sans CJK KR";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 166.667% */
  letter-spacing: -0.9px;
  background-color: white;
  margin: auto;
  word-break: keep-all;
  position: relative;
}
.introModalBox div:first-child {
  color: #000;
  text-align: center;
  font-family: "Noto Sans CJK KR";
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 68px; /* 151.111% */
  letter-spacing: -1.35px;
}
.introModalBox img {
  width: 300px;
  height: 300px;
}

.introModalArrowLeft {
  width: 80px;
  height: 120px;
  position: absolute;
  cursor: pointer;
  left: 4%;
  top: 50%;
  transform: translateY(-50%);
}
.introModalArrowRight {
  width: 80px;
  height: 120px;
  position: absolute;
  cursor: pointer;
  right: 4%;
  top: 50%;
  transform: translateY(-50%);
}
.introModalCloseBtn {
  position: absolute;
  top: 0;
  right: 7%;
  cursor: pointer;
}

.introModalBox .underline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-position: under;
  text-decoration-color: #007aff;
}
