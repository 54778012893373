.frame-screen {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 48px 48px;
  position: relative;
  width: 100%;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 268px;
}

.rectangle {
  align-self: stretch;
  height: 268px;
  object-fit: cover;
  position: relative;
  width: 268px;
}

.content {
  align-items: center;
  align-self: stretch;
  border-bottom: solid 0.5px #000000;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.text-wrapper {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  letter-spacing: -0.6px;
  white-space: nowrap;
}

.description {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.48px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description-expanded {
  max-height: 500px;
}

.arrow-down {
  cursor: pointer;
}

.arrow-up {
  cursor: pointer;
  transform: rotate(180deg);
}

.addWordFrame {
  width: 268px;
  height: 268px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
