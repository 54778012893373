.readNowContainer {
  height: 100%;
}

.answerListContainr {
  display: flex;
  padding: 12px 0px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #000;
  background: #fafafa;
}

.answerListQuestion {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.72px;
  cursor: pointer;
}

.answerListReqDate {
  color: #b0b0b0;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.54px;
}

.answerCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-bottom: 1px solid #000;
}

.answerCardQuestionBox {
  display: flex;
  padding-bottom: 20px;
  gap: 30px;
  align-self: stretch;
  border-bottom: 1px solid #bcbcbc;
  margin-top: 20px;
  align-items: center;
}

.answerCardQuestionBoxInner {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.answerCardQuestion {
  font-size: 24px;
  line-height: 30px; /* 125% */
  letter-spacing: -0.72px;
}
.answerCardregistDate {
  color: #b0b0b0;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.54px;
}
.answerCardMessageBox {
  font-size: 60px;
  line-height: 90px;
  letter-spacing: -1.8px;
  width: 100%;
  min-height: 1050px;
}

.playButton .circle {
  fill: #c2c2c2;
}

.playButton:hover .circle {
  fill: #007aff;
}

.readCardUnderline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-position: under;
  text-decoration-color: #007aff;
  cursor: pointer;
  position: relative;
}
.readCardImageTooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  left: 40px;
  bottom: -73px;
  display: none;
  width: 100%;
  height: 100%;
}
.readCardImageTooltip img {
  max-width: 300px;
}
.readCardUnderline:hover .readCardImageTooltip {
  display: block; /* hover 시에만 보임 */
}

.prev,
.next {
  border-bottom: 1px solid #000;
  background: #fafafa;
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.print {
  display: none;
}
.printBody {
  display: flex;
  width: 363px;
  padding: 60px 0px;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.printMessage {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  line-height: 42px;
  word-break: keep-all;
}

.printBtn {
  cursor: pointer;
}
