.header {
  align-items: flex-start;

  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 0px 24px;
  position: relative;
  width: 100%;
}

.header .class {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.header .logo {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.header .text-wrapper {
  color: #000000;
  font-family: "Inclusive Sans-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.6px;
  line-height: 30px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .actions-operations-renew-16 {
  left: 2px !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.header .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  padding: 0px 10px 0px 0px;
  position: relative;
}

.header .menu {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  cursor: pointer;
}
.div:hover {
  text-decoration: underline;
}
.header .div {
  color: #000000;
  font-family: "Inclusive Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.header .text-wrapper-2 {
  color: #000000;
  font-family: "Inclusive Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.header .text {
  align-self: stretch;
  color: #b9b9b9;
  font-family: "Noto Sans CJK KR-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -1.2px;
  line-height: 62px;
  position: relative;
}

.header .editable {
  outline: none;
  border-width: 0;
  width: 100%;
  z-index: 20;
  position: relative;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: -1.2px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #000000;
  padding-bottom: 24px;
}

.header [contenteditable="true"]:empty:before {
  content: attr(data-placeholder);
  color: #b9b9b9;
}

.outcome {
}
.outcome .header {
  z-index: 10;
  margin: 26px;
  height: fit-content;
  border-bottom-color: #ffffff;
}
.outcome .text-wrapper {
  color: #ffffff;
  z-index: 10;
}
.outcome .editable {
  color: #a6a6a6;
  z-index: 10;
}
.outcome .overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.35) 100%
  );
  height: 275px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.send {
  position: absolute;
  right: 0;
  bottom: 51px;
  cursor: pointer;
  z-index: 100;
}
.send-circle {
  fill: #959595;
}
.send:hover .send-circle {
  fill: #007aff;
}
